import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import usePlayBannerImages from "../../hooks/useplaybannerimages"
import { getImage } from "gatsby-plugin-image"

import quillSVG from "../../../scss/img/icon-quill-orange.svg"
import theatreSVG from "../../../scss/img/icon-theatre-orange.svg"
import textSVG from "../../../scss/img/icon-text-orange.svg"
import clipboardSVG from "../../../scss/img/icon-clipboard-orange.svg"
import whiteboardSVG from "../../../scss/img/icon-whiteboard-orange.svg"
import eyeSVG from "../../../scss/img/icon-eye-orange.svg"
import noteSVG from "../../../scss/img/icon-note-orange.svg"
import essaySVG from "../../../scss/img/icon-essay-orange.svg"
import downloadSVG from "../../../scss/img/icon-download-orange.svg"
import headphonesSVG from "../../../scss/img/icon-headphones-orange.svg"
import crownSVG from "../../../scss/img/icon-crown-orange.svg"
import starSVG from "../../../scss/img/icon-star-orange.svg"

const SIBProductPageFeatures = (props) => {
  const bannerImages = usePlayBannerImages()
  return (
    <section className="section bg-paper bg-tear-yellow">
      <div className="container">
        <div className="columns mb-5">
          <div className="column is-8 is-offset-2 has-text-centered">
            <div className="content">
              <h2 className="heading-large">Product Features</h2>
              <p>
                Key product features of Shakespeare In Bits — the only study
                guide you will need to teach, understand and appreciate
                Shakespeare's most celebrated works.
              </p>
            </div>
          </div>
        </div>

        <div className="features-panel">
          <div className="columns is-desktop">
            <div className="column is-8-desktop">
              <div className="content">
                <img
                  className="icon"
                  src={theatreSVG}
                  alt="The Play’s the thing…"
                />
                <h3 className="heading-medium">The Play’s the thing…</h3>
                <p>
                  Shakespeare’s plays are not merely texts to be read, but
                  scripts for dramas to be seen and heard. Shakespeare In Bits
                  uses modern digital media and communications technology to
                  deliver the plays in an innovative, media-rich format. In
                  addition to the unabridged text with full visuals and audio,
                  each title includes a comprehensive set of features to help
                  users get to grips with the language, plots, characters,
                  themes and the many other engaging attributes of the Bard’s
                  great works.
                </p>
                <p>
                  The basic idea is simple: don’t just read the play, watch it
                  and hear it too, in one integrated package!
                </p>
              </div>
            </div>
            <div className="column image-column">
              <GatsbyImage
                image={getImage(bannerImages.product_features_macbeth)}
                alt="Shakespeare in Bits: Product Features"
              />
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="box icon-box icon-box-feature">
              <img className="image" src={quillSVG} alt="Play Text" />
              <h3 className="heading-medium">Play Text</h3>
              <p>
                Study of Shakespeare begins with the texts — the original
                “source of truth”. Our goal is to deliver Shakespeare in a
                format that appeals to contemporary learners. But preserving the
                complete, original text of the plays was the starting point.
                While the scenes are broken into more manageable “Bits”, they
                deliver a true and accurate standard text.
              </p>
            </div>
            <div className="box icon-box icon-box-feature">
              <img className="image" src={textSVG} alt="Text Support Features" />
              <h3 className="heading-medium">Text Support Features</h3>
              <p>
                Several features are provided to support the reader of text
                which, while brilliant, is also archaic, highly stylized, dense,
                and multilayered by turns. These include on-demand in-line
                “translations” in modern English, margin notes explaining
                themes, plots and subplots, historical references, literary
                devices, puns, and other points of interest.
              </p>
            </div>
            <div className="box icon-box icon-box-feature">
              <img className="image" src={clipboardSVG} alt="Analysis" />
              <h3 className="heading-medium">Analysis</h3>
              <p>
                Academics, philosophers, writers, politicians ... Shakespeare’s
                works enjoy universal appeal — a testament to how much he had to
                say on any given topic! Shakespeare In Bits contains
                comprehensive notes explaining and analyzing each segment or
                “Bit” of each play, to help the reader delve into his abundant
                ideas, themes, observations, and moral dilemmas.
              </p>
            </div>
            <div className="box icon-box icon-box-feature">
              <img className="image" src={whiteboardSVG} alt="Lesson Plans" />
              <h3 className="heading-medium">Lesson Plans</h3>
              <p>
                Sample lesson plans are included for teacher and school users as
                an additional optional source of ideas for ways in which to
                incorporate the Shakespeare In Bits digital resources into the
                classroom. We plan to expand on these and several of the other
                great features in the coming year.
              </p>
            </div>
          </div>

          <div className="column">
            <div className="box icon-box icon-box-feature">
              <img className="image" src={eyeSVG} alt="Visualization" />
              <h3 className="heading-medium">Visualization</h3>
              <p>
                A common challenge for newly initiated readers of Shakespeare is
                the difficulty of visualizing the events unfolding, given the
                more immediate challenges of his bountiful but often riddlesome
                text. Shakespeare In Bits provides a full, animated rendering of
                the action to assist with this.
              </p>
            </div>
            <div className="box icon-box icon-box-feature">
              <img className="image" src={noteSVG} alt="Synopses and Summaries" />
              <h3 className="heading-medium">Synopses and Summaries</h3>
              <p>
                Every scene has an easily accessible summary and every “Bit”
                into which the play is subdivided, has a synopsis. These
                features ensure that the student is always a single click away
                from insightful explanations, should they need to look further
                than the text and its in-line support features.{" "}
              </p>
            </div>
            <div className="box icon-box icon-box-feature">
              <img className="image" src={essaySVG} alt="Essays" />
              <h3 className="heading-medium">Essays</h3>
              <p>
                To provide overviews on certain key aspects and to facilitate
                exam preparation, engaging essays are included on themes,
                imagery, key quotes, and on historical and other aspects of
                Shakespeare’s work.
              </p>
            </div>
            <div className="box icon-box icon-box-feature">
              <img
                className="image"
                src={downloadSVG}
                alt="Downloadable PDF Texts"
              />
              <h3 className="heading-medium">Downloadable PDF Texts</h3>
              <p>
                In addition to the many resources provided by the digital
                program, some teachers and students may wish to read or annotate
                their own copy of the play text as part of their study. To
                facilitate this, downloadable PDF texts that match the on-screen
                play text, are included for teacher and school users.
              </p>
            </div>
          </div>

          <div className="column">
            <div className="box icon-box icon-box-feature">
              <img className="image" src={headphonesSVG} alt="Audio" />
              <h3 className="heading-medium">Audio</h3>
              <p>
                To fully engage with Shakespeare’s language and the nuances that
                may be communicated during its delivery, it needs to be spoken
                and heard. Shakespeare In Bits provides a full professional
                audio soundtrack featuring the voices of renowned actors
                including Kate Beckinsale, Michael Sheen, Fiona Shaw, Anton
                Lesser, Emma Fielding, Michael Maloney and Stephen Dillane.
              </p>
            </div>
            <div className="box icon-box icon-box-feature">
              <img className="image" src={crownSVG} alt="Character Bios" />
              <h3 className="heading-medium">Character Bios</h3>
              <p>
                Becoming familiar with the play’s cast is key to appreciating
                the dramatic events unfolding, the motivations of the
                characters, their traits and personalities, and their roles in
                advancing the action. Character bios are easily accessible from
                within the play area, and relationships are also visually
                represented for quick assimilation.
              </p>
            </div>
            <div className="box icon-box icon-box-feature">
              <img className="image" src={starSVG} alt="Quizzes" />
              <h3 className="heading-medium">Quizzes</h3>
              <p>
                Comprehensive understanding of the plays is facilitated by
                knowledge of the characters, plots, terminology, important
                lines, etc. To assist with this, quizzes are provided that cover
                the whole play as well as individual acts. School users have
                options to vary the focus of these quizzes by knowledge domain
                and by scene.
              </p>
            </div>
          </div>
        </div>

        <div className="columns mt-5">
          <div className="column is-8 is-offset-2 has-text-centered">
            <div className="content">
              <p className="heading-tagline">
                Trusted by teachers and parents worldwide
              </p>
              <p>
                <a className="text-link" href="/">
                  More information for educators
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SIBProductPageFeatures
