import React from "react"

import sealSVG from "../../../scss/img/sib-seal-orange.svg"

const SIBProductPageIntro = (props) => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Animated visuals, audio and play text in one package</h1>
              <p>
                Shakespeare In Bits brings William Shakespeare’s most popular
                plays to life like never before — it's a whole new way to
                experience, teach, study and appreciate the linguistic richness
                of The Bard’s work.
              </p>
              <img
                className="seal"
                src={sealSVG}
                alt="Shakespeare in Bits Seal"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2 has-text-centered">
              <div className="content">
                <h2 className="heading-large-alt">
                  Shakespeare, as you like it!
                </h2>
                <p>
                  Starting from humble beginnings in 2010 as a single play in CD
                  format, our popular Shakespeare In Bits resource has grown
                  into a broad suite of learning materials that are now
                  available online! Superfast and packed with features, the
                  upgraded application is accessible to anyone on any device
                  with a web connection and a standard browser.
                </p>
                <p>
                  Shakespeare In Bits today is trusted by teachers and parents
                  worldwide as an effective tool for helping students not just
                  to understand but appreciate the timeless fountain of literary
                  flourish, wisdom, wit, philosophy, and human observation that
                  Shakespeare’s works embody. Teachers, students, actors, casual
                  readers, academics... regardless of your profile and
                  requirement, Shakespeare In Bits is ever at your service, just
                  the way you like it!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBProductPageIntro
