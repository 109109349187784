import React from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import usePlayBannerImages from "../../hooks/useplaybannerimages"
import { getImage } from "gatsby-plugin-image"

const SIBProductPagePlays = (props) => {
  const bannerImages = usePlayBannerImages()
  return (
    <>
      <section className="section bg-tear-paper pt-0">
        <div className="container">
          <div className="columns mb-5">
            <div className="column is-8 is-offset-2 has-text-centered">
              <div className="content">
                <h2 className="heading-large">Play Titles</h2>
                <p>
                  Five plays are currently available in the series, chosen for
                  being among the most widely studied across the second level
                  education tier.
                </p>
              </div>
              {/*<!-- Plays: Quicklinks -->*/}
              <ul className="quicklinks">
                <li>
                  <a className="button is-rounded" href="#romeo-juliet">
                    Romeo and Juliet
                  </a>
                </li>
                <li>
                  <a className="button is-rounded" href="#macbeth">
                    Macbeth
                  </a>
                </li>
                <li>
                  <a
                    className="button is-rounded"
                    href="#midsummer-nights-dream"
                  >
                    A Midsummer Night's Dream
                  </a>
                </li>
                <li>
                  <a className="button is-rounded" href="#hamlet">
                    Hamlet
                  </a>
                </li>
                <li>
                  <a className="button is-rounded" href="#julius-caesar">
                    Julius Caesar
                  </a>
                </li>
              </ul>
              {/*<!-- /Plays: Quicklinks -->*/}
            </div>
          </div>

          {/*<!-- Play: Romeo and Juliet -->*/}
          <div className="play-panel" id="romeo-juliet">
            <div className="columns is-desktop">
              <div className="column is-7-desktop">
                <div className="play-description">
                  <h3 className="play-title">Romeo and Juliet</h3>
                  <p>
                    Fall in love with Shakespeare’s timeless story of love,
                    vengeance and tragedy. Shakespeare In Bits: Romeo & Juliet
                    promises to engage the mind and wrench the hearts of all who
                    view, read and listen to it. The ultimate study guide, this
                    is everything you will ever need to understand, teach and
                    appreciate one of the most enduring plays of all time.
                  </p>
                  <figure>
                    <blockquote>
                      For never was a story of more woe <br />
                      Than this of Juliet and her Romeo
                    </blockquote>
                    <figcaption>— Act 5 Scene 3</figcaption>
                  </figure>
                </div>
              </div>
              <div className="column image-column">
                <GatsbyImage
                  className="play-image"
                  image={getImage(bannerImages.raj_banner_fixed)}
                  alt="Shakespeare in Bits: Romeo and Juliet"
                />
              </div>
            </div>
          </div>
          {/*<!-- /Play: Romeo and Juliet -->*/}

          {/*<!-- Play: Macbeth -->*/}

          <div className="play-panel reverse" id="macbeth">
            <div className="columns is-desktop">
              <div className="column is-7-desktop">
                <div className="play-description">
                  <h3 className="play-title">Macbeth</h3>
                  <p>
                    Read, watch and listen to one of Shakespeare's most
                    widely-studied plays as Shakespeare In Bits brings to life
                    the tragic tale of the murderous Macbeth in one complete
                    digital experience. Shakespeare In Bits: Macbeth is the
                    only study guide you will need to understand, teach and
                    appreciate one of the finest works of the famous Bard.{" "}
                  </p>
                  <figure>
                    <blockquote>
                      By the pricking of my thumbs, Something wicked this way
                      comes. Open, locks, Whoever knocks!
                    </blockquote>
                    <figcaption>— Act 4 Scene 1</figcaption>
                  </figure>
                </div>
              </div>
              <div className="column image-column">
                <GatsbyImage
                  className="play-image"
                  image={getImage(bannerImages.macbeth_banner_fixed)}
                  alt="Shakespeare in Bits: Macbeth"
                />
              </div>
            </div>
          </div>
          {/*<!-- /Play: Macbeth -->*/}

          {/*<!-- Play: A Midsummer Night’s Dream -->*/}

          <div className="play-panel" id="midsummer-nights-dream">
            <div className="columns is-desktop">
              <div className="column is-7-desktop">
                <div className="play-description">
                  <h3 className="play-title">A Midsummer Night's Dream</h3>
                  <p>
                    Enjoy this magical romp through one of Shakespeare’s most
                    enduringly funny plays where not everything goes quite to
                    plan and magical chaos ensues, resulting in hilarious
                    consequences. Shakespeare In Bits: A Midsummer Night's Dream
                    is the only study guide you will need to understand, teach
                    and appreciate one of the Bard's most intriguing comedies.
                  </p>
                  <figure>
                    <blockquote>
                      The course of true love never did run smooth.
                    </blockquote>
                    <figcaption>— Act 1 Scene 1</figcaption>
                  </figure>
                </div>
              </div>
              <div className="column image-column">
                <GatsbyImage
                  className="play-image"
                  image={getImage(bannerImages.msnd_banner_fixed)}
                  alt="Shakespeare in Bits: A Midsummer Night’s Dream"
                />
              </div>
            </div>
          </div>
          {/*<!-- /Play: A Midsummer Night’s Dream -->*/}

          {/*<!-- Play: Hamlet -->*/}

          <div className="play-panel reverse" id="hamlet">
            <div className="columns is-desktop">
              <div className="column is-7-desktop">
                <div className="play-description">
                  <h3 className="play-title">Hamlet</h3>
                  <p>
                    Immerse yourself in one of Shakespeare’s most powerful and
                    influential tragedies as Shakespeare In Bits brings you on a
                    journey of deception, revenge, treachery, indecision and
                    moral decay through the fabled and tragic character of
                    Hamlet. Shakespeare In Bits: Hamlet is the only study guide
                    you will need to teach, understand and appreciate one of
                    Shakespeare's most celebrated works.
                  </p>
                  <figure>
                    <blockquote>
                      Something is rotten in the state of Denmark.
                    </blockquote>
                    <figcaption>— Act 1 Scene 4</figcaption>
                  </figure>
                </div>
              </div>
              <div className="column image-column">
                <GatsbyImage
                  className="play-image"
                  image={getImage(bannerImages.hamlet_banner_fixed)}
                  alt="Shakespeare in Bits: Hamlet"
                />
              </div>
            </div>
          </div>
          {/*<!-- /Play: Hamlet -->*/}

          {/*<!-- Play: Julius Caesar -->*/}

          <div className="play-panel" id="julius-caesar">
            <div className="columns is-desktop">
              <div className="column is-7-desktop">
                <div className="play-description">
                  <h3 className="play-title">Julius Caesar</h3>
                  <p>
                    Travel back in time to ancient Rome and join the intrigue as
                    a group of disgruntled citizens plot a conspiracy against
                    their illustrious dictator. Shakespeare In Bits: Julius
                    Caesar puts you right at the bloody heart of this tale of
                    loyalty, ambition, power, and betrayal that marked the end
                    of the great republic. This engaging digital study guide
                    will help you get to grips with Shakespeare's foremost
                    political thriller.
                  </p>
                  <figure>
                    <blockquote>
                      There is a tide in the affairs of men.
                      <br />
                      Which, taken at the flood, leads on to fortune
                    </blockquote>
                    <figcaption>— Act 4 Scene 3</figcaption>
                  </figure>
                </div>
              </div>
              <div className="column image-column">
                <GatsbyImage
                  className="play-image"
                  image={getImage(bannerImages.jc_banner_fixed)}
                  alt="Shakespeare in Bits: Julius Caesar"
                />
              </div>
            </div>
          </div>
          {/*<!-- /Play: Julius Caesar -->*/}
        </div>
      </section>
    </>
  )
}

export default SIBProductPagePlays
