import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"

import SIBProductPageIntro from "../components/frontend/productpage/intro"
import SIBProductPagePlays from "../components/frontend/productpage/plays"
import SIBProductPageFeatures from "../components/frontend/productpage/features"

import SIBCommonTestimonials from "../components/frontend/common/testimonials"
import SIBCommonSignupCTA from "../components/frontend/common/signupcta"

const ProductPage = ({ data }) => {
  return (
    <Layout showFrontendLinks={true}>
      <Seo title="Shakespeare In Bits - Product" />
      <SIBProductPageIntro/>
      <SIBProductPagePlays/>
      <SIBProductPageFeatures/>
      <SIBCommonTestimonials/>
      <SIBCommonSignupCTA/>
    </Layout>
  )
}

export default ProductPage